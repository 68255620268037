<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="deleteAttachment"/>
        <ASuccessFour :api="api"/>
        <v-row class="mt-3">
            <v-col align="center" justify="center" cols="12" sm="8" class="mx-auto">
                <v-col align="start" justify="start">
                    <v-btn
                    @click="redirectBack"
                    class="mb-3"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-card class="pa-3" elevation-1>
                    <!--BOC : Create a form here-->
                    <v-toolbar color="secondary white--text" class="text-h5">
                        Update step
                    </v-toolbar>
                    <AError :api="this.api"/>
                    <!-- <ASuccessThree :api="api"/> -->
                    <v-text-field
                    class="mt-5"
                    dense
                    outlined
                    v-model="title"
                    label="Title">

                    </v-text-field>
                    <mavon-editor
                    :toolbars="toolbars"
                    style="z-index:0;min-height:400px;height:auto;width:100%"
                    height="auto"
                    width="auto"
                    min-height="400px"
                    :subfield="false"
                    language="en"
                    v-model="step"
                    placeholder="New step"
                    class="my-5"
                    :editable="true">
                    </mavon-editor>

                    <!-- <v-text-field
                    class="mt-5"
                    label="Step">
                    </v-text-field> -->
                    <div class="d-flex justify-start" v-if="attachmentName!=null">
                        Attachment :
                        <a class="px-2" :href="attachmentUrl" target="_blank"
                        prepend-icon="mdi-delete"
                        >   
                            {{ attachmentName}}
                        </a>
                        <!-- <v-img 
                        @mouseout="shrinkImageSize" 
                        @mouseover="expandImageSize" 
                        @click="redirectReadImage(item.attachment_url)" v-if="item.attachmentUrl!=null" class="mx-auto" :src=attachmentUrl 
                        :width="imageSize"
                        :style="{cursor:cursorStyle}" >
                        </v-img> -->
                        <v-icon @click="confirmDelete(attachmentId)" color="red">
                            mdi-delete
                        </v-icon>
                    </div>
                    <v-file-input
                    v-else
                    :multiple="false"
                    accept="image/png, image/jpeg "
                    prepend-icon="mdi-camera"
                    color="primary"
                    v-model="attachment"
                    outlined
                    label="Upload image (if have any)">

                    </v-file-input>

                    <div class="d-flex justify-start mt-2">
                        <v-text-field v-model="order" dense outlined label="Order">

                        </v-text-field>
                    </div>

                    <div class="d-flex justify-end">
                        <v-btn color="primary" @click="redirectBack" plain>
                            Cancel
                        </v-btn>
                        <v-btn color="primary" @click="submit" :loading="api.isLoading">
                            Submit
                        </v-btn>
                    </div>

                    <!--EOC-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import mavonEditor from 'mavon-editor';
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default {
    components:{
    AConfirmation,
    "mavon-editor": mavonEditor.mavonEditor,
    ASuccessFour
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        toolbars: {
            bold: true, 
            italic: true, 
            header: true, 
            underline: true,
            strikethrough: true,
            mark: true, 
            superscript: true,
            subscript: true,
            quote: true,
            ol: true, 
            ul: true, 
            fullscreen: true,
            readmodel: true,
            undo: true, 
            redo: true, 
            trash: true,
            navigation: true,
            alignleft: true,
            aligncenter: true,
            alignright: true, 
            subfield: true,
            preview: true, 
        },
        order:null,

        deleteId:null,
        attachment:null,
        attachmentUrl:null,
        attachmentName:null,
        attachmentId:null,
        title:null,
        step:null,
        isPending:false,
        api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
            success:null,
        }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="deleteManualStepAttachment"){
                location.reload();
            }
            if(resp.class=="updateManualStep"){
                this.api.isSuccesful = true;
                this.api.success="Succesfully update the manual step";
                // this.$router.go(-1);
            }
            if(resp.class=="getManualStep"){
                this.title = resp.data.title;
                this.step = resp.data.step;
                this.order = resp.data.order;
                this.attachmentName = (resp.data.attachment_name!=null)?resp.data.attachment_name:null;
                this.attachmentUrl=(resp.data.attachment_url!=null)?resp.data.attachment_url:null;
                this.attachmentId = resp.data.attachment_id;

            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
    this.fetch();
    },
    methods: {
        fetch(){
            let manualStepApi = this.fetchManualStep();
            this.$api.fetch(manualStepApi);
        },
        fetchManualStep(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/manual/step/"+this.$router.history.current.params.id
            return tempApi;
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let updateManualStep = this.update();
            this.$api.fetch(updateManualStep);
        },
        cancelSubmit(){
            this.isPending = true;
        },
        update(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/manual/step/withAttachment/"+this.$router.history.current.params.id;
            let formData = new FormData;
            formData.append('order',this.order);
            formData.append('title',this.title);
            formData.append('step',this.step);
            if(this.attachment!=null){
                let allowedFiles = ["image/jpeg","image/png"];

                if(!allowedFiles.includes(this.attachment.type)){
                    this.api.isError = true;
                    this.api.error = "Invalid form, only support .png, .jpeg, and .jpg";
                    return 1;
                }
                formData.append('attachmentType','Manual');
                formData.append('attachment',this.attachment);
                formData.append('userEmail',this.$store.getters.getEmail);
            }
            tempApi.params =formData;
            // tempApi.params={
            //     "title":this.title,
            //     "step":this.step,
            //     "attachment":(this.attachment!=null)?this.attachment:null,
            //     "attachmentType":(this.attachment!=null)?"Manual":null,
            //     // "attachment":formData.attachment,
            //     // 'attachmentType':formData.attachmentType,
            // }
           
            return tempApi;
        },
        redirectBack(){
            this.$router.go(-1);
        },
       
        confirmDelete(id){
            this.isPending = true;
            this.deleteId = id;
        },
        deleteAttachment(){
            this.isPending = false;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "DELETE";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/manual/step/attachment/"+this.deleteId;
            this.$api.fetch(tempApi);
        }
    }
}
</script>